import React, { Component } from 'react';
import $ from 'jquery';

import {
    Container,
    Loading,
    Link,
    Button,
    AudioPlayer,
    TextArea,
    Image,
    Accordion,
} from 'library';
import {
    ajax_wrapper,
    get_global_state,
    sort_objects,
    toast_handler,
} from 'functions';
import {
    TagButton,
    DynamicSearch,
    BPM,
    KeyFinder,
    SentenceOptions,
    SongListItem,
} from 'components';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.song-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

class TagType extends Component {
    render() {
        let tags = [];
        for (let item of this.props.data['tags']) {
            if (item['selectable'] && item['children'].length == 0) {
                let active = '';
                if (this.props.song_tags.includes(item['id'])) {
                    active = 'active';
                }

                tags.push(
                    <TagButton
                        data={item}
                        active={active}
                        toggle_tag={this.props.toggle_tag}
                    />,
                );
            } else {
                tags.push(<div>{item['name']}</div>);

                for (let child of item['children']) {
                    let active = '';
                    if (this.props.song_tags.includes(child['id'])) {
                        active = 'active';
                    }

                    tags.push(
                        <TagButton
                            data={child}
                            active={active}
                            toggle_tag={this.props.toggle_tag}
                        />,
                    );
                }
            }
        }

        return (
            <div className="simple-card">
                <h6>{this.props.data['name']}</h6>
                <div>{tags}</div>
            </div>
        );
    }
}
//
export default class Song extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            data: {
                tags: [],
                tag_types: [],
            },
            show_search: false,
            search_type_name: '',
            search_type: '',
            tags: [],
            tag_lookup: {},
            tag_type_reverse_lookup: {},
            recent_songs: [],
            sentences: {},
            loading_sentences: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', `/api/song_details/${params['id']}/`, {}, (value) =>
            this.setState(
                { data: value },
                function () {
                    ajax_wrapper(
                        'GET',
                        `/api/taxonomy_tags/${this.state.data['taxonomy_id']}/`,
                        {},
                        this.load_tags,
                    );
                    ajax_wrapper(
                        'GET',
                        `/api/recent_songs/${this.state.data['project']}/`,
                        {},
                        (value) => {
                            this.setState({ recent_songs: value });
                        },
                    );
                }.bind(this),
            ),
        );

        focus_on_page();
    }

    handle_key_press = (event) => {
        if (
            ((event.ctrlKey || event.metaKey) && event.key == 'f') ||
            (event.altKey && event.metaKey && event.key == ' ')
        ) {
            event.cancelBubble = true;
            event.preventDefault();
            this.setState({ show_search: true });
        }
    };

    load_tags = (value) => {
        console.log('value', value);
        let tag_lookup = {};

        for (let tagtype of value) {
            tag_lookup[tagtype['id']] = tagtype;
            for (let item of tagtype['tags']) {
                tag_lookup[item['id']] = item;
                for (let child of item['children']) {
                    tag_lookup[child['id']] = child;
                }
            }
        }

        this.setState({
            loaded: true,
            tags: value,
            tag_lookup: tag_lookup,
        });
    };

    toggle_tag = (id) => {
        let params = get_global_state()['params'];

        let data = {
            add: [],
            remove: [],
        };

        let found_tag = null;
        for (let item of this.state.data['tags']) {
            if (item['id'] == id) {
                found_tag = this.state.data['tags'].indexOf(item);
            }
        }
        if (found_tag == null) {
            data['add'].push(id);
            this.state.data['tags'].push(this.state.tag_lookup[id]);
        } else {
            data['remove'].push(id);
            this.state.data['tags'].splice(found_tag, 1);
        }

        sort_objects(this.state.data['tags'], ['name']);

        this.setState(
            {
                data: this.state.data,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${params['id']}/`,
                    data,
                    (value) => toast_handler(value, null),
                ),
        );
    };

    update_details = (value) => {
        let new_data = Object.assign(this.state.data, value);
        this.setState({
            data: new_data,
            loaded: true,
        });
    };

    save_details = () => {
        let params = get_global_state()['params'];

        let data = {
            bpm: this.state.data['bpm'],
            key: this.state.data['key'],
            major_minor: this.state.data['major_minor'],
            description: this.state.data['description'],
            stripped_description: this.state.data['stripped_sentence'],
        };

        ajax_wrapper(
            'POST',
            `/api/home/song/${params['id']}/`,
            data,
            function () {
                window.location = `/project/${this.state.data['project']}/`;
            }.bind(this),
        );
    };

    update_presets = (id) => {
        this.setState(
            { loaded: false },
            ajax_wrapper(
                'POST',
                `/api/update_presets/${this.state.data['id']}/`,
                {},
                (value) => this.update_details(value),
            ),
        );
    };

    get_sentences = () => {
        this.setState({ loading_sentences: true }, () =>
            ajax_wrapper(
                'GET',
                `/api/get_sentences/${this.state.data['id']}/`,
                {},
                (value) =>
                    this.setState({
                        sentences: value,
                        loading_sentences: false,
                    }),
            ),
        );
    };

    getGPTSentence = () => {
        ajax_wrapper(
            'GET',
            `/api/get_GPT_sentence/${this.state.data['id']}/`,
            {},
            (value) => this.update_details({ description: value['sentence'] }),
        );
    };

    copy_tags = (id) => {
        this.setState(
            { loaded: false },
            ajax_wrapper(
                'POST',
                `/api/copy_tags/${this.state.data['id']}/${id}/`,
                {},
                (value) => this.update_details(value),
            ),
        );
    };

    alt_mix = (id) => {
        this.setState({ loaded: false }, () =>
            ajax_wrapper(
                'POST',
                `/api/copy_tags/${this.state.data['id']}/${id}/`,
                { parent_mix: true },
                (value) => this.update_details(value),
            ),
        );
    };

    render() {
        let tag_lookup = this.state.tag_lookup;
        let current_tag_types = {};
        for (let tag_type of this.state.data['tag_types']) {
            current_tag_types[tag_type['id']] = [];
        }

        let song_tags = [];
        for (let item of this.state.data['tags']) {
            song_tags.push(item['id']);
            current_tag_types[item['tag_type']].push(item);
        }

        let current_tags = [];

        for (let type in current_tag_types) {
            let tags = [];
            for (let tag of current_tag_types[type]) {
                tags.push(
                    <TagButton
                        data={tag}
                        active={'active'}
                        toggle_tag={this.toggle_tag}
                    />,
                );
            }

            current_tags.push(
                <div className="col-4">
                    <div className="simple-card">
                        <Button
                            className="tagtype-header-button"
                            type="primary"
                            onClick={() =>
                                this.setState({
                                    show_search: true,
                                    search_type: tag_lookup[type]
                                        ? tag_lookup[type]['name']
                                        : '',
                                    search_type_id: tag_lookup[type]['id'],
                                })
                            }
                        >
                            {tag_lookup[type] ? tag_lookup[type]['name'] : null}
                        </Button>
                        {tags}
                    </div>
                </div>,
            );
        }

        let tag_types = [];
        for (let item of this.state.tags) {
            tag_types.push(
                <TagType
                    data={item}
                    song_tags={song_tags}
                    toggle_tag={this.toggle_tag}
                />,
            );
        }

        let recent_songs = [];
        for (let item of this.state.recent_songs) {
            if (item['id'] == this.state.data['id']) {
                continue;
            }

            recent_songs.push(
                <div className="col-12">
                    <SongListItem
                        data={item}
                        onClick={() => this.copy_tags(item['id'])}
                    >
                        <div style={{ marginTop: '4px', float: 'right' }}>
                            <Button
                                type="primary"
                                onClick={() => this.alt_mix(item['id'])}
                            >
                                Alt Mix
                            </Button>
                        </div>
                    </SongListItem>
                </div>,
            );
        }

        return (
            <div
                class="song-page-container"
                onKeyDown={this.handle_key_press}
                tabIndex="0"
            >
                <Container>
                    <Loading loaded={this.state.loaded} cover={true}>
                        <DynamicSearch
                            show={this.state.show_search}
                            tag_type_name={this.state.search_type}
                            tag_type={this.state.search_type_id}
                            data={this.state.data}
                            project_view={false}
                            tags={this.state.tags}
                            tag_lookup={this.state.tag_lookup}
                            toggle_tag={this.toggle_tag}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_search: false,
                                        search_type: '',
                                        search_type_id: '',
                                    },
                                    focus_on_page,
                                )
                            }
                        />
                        <div className="simple-card-container">
                            <div className="simple-card">
                                <Link
                                    href={`/project/${this.state.data['project']}`}
                                >
                                    Back to project
                                </Link>
                            </div>
                            <div className="simple-card">
                                <AudioPlayer
                                    src={this.state.data['dropbox_url']}
                                    style={{ float: 'right' }}
                                />
                                <h1>{this.state.data.name}</h1>
                            </div>
                        </div>
                        <div
                            className="simple-card-container row"
                            style={{ marginLeft: '0px', marginRight: '0px' }}
                        >
                            <h4>Instructions:</h4>
                            <div>
                                <p>
                                    {this.state.data.project
                                        ? this.state.data.project.project_notes
                                        : null}
                                </p>
                            </div>
                        </div>
                        <div
                            className="simple-card-container row"
                            style={{ marginLeft: '0px', marginRight: '0px' }}
                        >
                            <div className="col-4">
                                <div className="simple-card">
                                    <div>
                                        <h3>Recent Songs</h3>
                                        <div
                                            className="simple-card-container row"
                                            style={{
                                                margin: '0px',
                                                maxHeight: '310px',
                                                overflow: 'auto',
                                            }}
                                        >
                                            {recent_songs}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="simple-card">
                                    <BPM
                                        value={this.state.data['bpm']}
                                        update={this.update_details}
                                    />
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="simple-card">
                                    <KeyFinder
                                        value={this.state.data['key']}
                                        major_minor={
                                            this.state.data['major_minor']
                                        }
                                        update={this.update_details}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="simple-card">
                                    <Button
                                        style={{ float: 'right' }}
                                        type="info"
                                        onClick={this.update_presets}
                                    >
                                        Update Presets
                                    </Button>
                                    <h4>{'Current Tags'}</h4>
                                </div>
                            </div>
                            {current_tags}

                            <div className="simple-card">
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        this.setState({
                                            show_search: true,
                                        })
                                    }
                                >
                                    Search All Tags
                                </Button>
                            </div>

                            <div
                                className="simple-card"
                                style={{ position: 'relative' }}
                            >
                                <Loading
                                    loaded={!this.state.loading_sentences}
                                    cover={true}
                                >
                                    <h4>Current Description:</h4>
                                    <TextArea
                                        style={{ fontSize: '18px' }}
                                        value={this.state.data['description']}
                                        handle_change={(event) =>
                                            this.update_details({
                                                description:
                                                    event.currentTarget.value,
                                            })
                                        }
                                    />
                                    <SentenceOptions
                                        data={this.state.sentences}
                                        update={(value) =>
                                            this.update_details(value)
                                        }
                                    />
                                    <br />
                                    <row>
                                        <Button
                                            type="danger"
                                            onClick={this.get_sentences}
                                        >
                                            Generate Sentences!
                                        </Button>
                                        <Button
                                            type="info"
                                            style={{
                                                backgroundColor: '#74aa9c',
                                                borderColor: '#74aa9c',
                                            }}
                                            onClick={this.getGPTSentence}
                                        >
                                            Generate{' '}
                                            <Image
                                                src="/static/images/openai.png"
                                                style={{ maxHeight: '16px' }}
                                            />{' '}
                                            GPT-4o Sentence!
                                        </Button>
                                    </row>
                                </Loading>
                            </div>
                            <div className="simple-card">
                                <Button
                                    type="success"
                                    onClick={this.save_details}
                                >
                                    Save & Return to Project
                                </Button>
                            </div>
                        </div>
                        <br />
                        <br />
                    </Loading>
                </Container>
            </div>
        );
    }
}
