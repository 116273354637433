import React, { Component } from 'react';

import { Container, Loading, Button, DeleteButton, Dropdown } from 'library';
import {
    ajax_wrapper,
    get_global_state,
    sort_objects,
    toast_handler,
} from 'functions';
import { SongCard, DynamicSearch } from 'components';
import $ from 'jquery';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.project-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            data: {},
            songs: [],
            editing_track_id: '',
            show_search: false,
            search_type: '',
            search_type_id: '',
            tag_lookup: {},
            song_tags: {},
            filter_threshold: 100,
            filter_active: true,
            include_empty: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/project_details/${params['id']}/`,
            {},
            (value) => {
                let songs = [];
                let song_tags = {};
                for (let song of value.songs) {
                    songs.push(song);
                    song_tags[song.id] = song.tags;
                }
                this.setState(
                    {
                        loaded: true,
                        data: value,
                        songs: songs,
                        song_tags: song_tags,
                    },
                    this.load_tags(value['taxonomy_tags']),
                );
            },
        );
    }

    delete_project = () => {
        ajax_wrapper(
            'POST',
            `/api/delete_project/${this.state.data.id}/`,
            {},
            (value) => (window.location = `/`),
        );
    };

    load_tags = (value) => {
        let tag_lookup = {};

        for (let tagtype of value) {
            tag_lookup[tagtype['id']] = tagtype;
            for (let item of tagtype['tags']) {
                tag_lookup[item['id']] = item;
                for (let child of item['children']) {
                    tag_lookup[child['id']] = child;
                }
            }
        }

        this.setState({
            tag_lookup: tag_lookup,
        });
    };

    toggle_song_tag = (id, song_id) => {
        let data = {
            add: [],
            remove: [],
        };

        let tags = [...this.state.song_tags[song_id]];
        let found_tag = null;

        for (let item of tags) {
            if (item['id'] == id) {
                found_tag = tags.indexOf(item);
            }
        }

        if (found_tag == null) {
            data['add'].push(id);
            tags.push(this.state.tag_lookup[id]);
        } else {
            data['remove'].push(id);
            tags.splice(found_tag, 1);
        }

        sort_objects(tags, ['name']);

        let song_tags = { ...this.state.song_tags };
        song_tags[song_id] = tags;

        this.setState(
            {
                song_tags: song_tags,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${song_id}/`,
                    data,
                    (value) => toast_handler(value, null),
                ),
        );
    };

    show_search = (type_id, search_type, track_id, track_tags) => {
        this.setState({
            search_type: search_type,
            search_type_id: type_id,
            editing_track_id: track_id,
            show_search: true,
        });
    };

    export_project = (format_id) => {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/project_export/${params['id']}/${format_id}/`,
            {},
            (value) => {
                const element = document.createElement('a');
                const file = new Blob([value['text']], { type: 'text/csv' });
                element.href = URL.createObjectURL(file);
                element.download = value['filename'];
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            },
        );
    };

    render() {
        let params = get_global_state()['params'];

        let songs = [];
        let to_be_tagged = 0;
        let in_progress = 0;
        let complete = 0;

        for (let song of this.state.songs) {
            if (song.complete) {
                complete++;
            } else if (song.being_worked_on) {
                in_progress++;
            } else {
                to_be_tagged++;
            }

            // calculate minimum tag confidence of ML tags
            let ml_confidence_ratings = [];
            for (let tag of this.state.song_tags[song.id]) {
                if (tag.ml_generated) {
                    ml_confidence_ratings.push(tag.ml_confidence);
                } else {
                    ml_confidence_ratings.push(1);
                }
            }
            let song_ml_confidence = Math.min(...ml_confidence_ratings);
            if (
                (ml_confidence_ratings.length === 0 &&
                    this.state.include_empty) |
                (song_ml_confidence <= this.state.filter_threshold / 100)
            ) {
                songs.push(
                    <SongCard
                        key={`${song.id}-${
                            this.state.song_tags[song.id].length
                        }`}
                        id={song.id}
                        data={song}
                        editable={true}
                        tags={this.state.song_tags[song.id]}
                        show_search={this.show_search}
                        toggle_tag={(id) => this.toggle_song_tag(id, song.id)}
                    />,
                );
            }
        }

        let export_options = [];
        if (this.state.data['export_formats']) {
            for (let format of this.state.data['export_formats']) {
                export_options.push(
                    <Button
                        type="primary"
                        onClick={() => {
                            this.export_project(format['id']);
                        }}
                    >
                        {format['name']}
                    </Button>,
                );
            }
        }

        return (
            <div className="project-page-container">
                <Container>
                    <Loading loaded={this.state.loaded}>
                        <div className="simple-card-container">
                            <div className="simple-card">
                                <div>
                                    <DeleteButton
                                        className="project-button"
                                        type="danger"
                                        url="/api/delete_project/"
                                        callback={() => (window.location = '/')}
                                        value={this.state.data.id}
                                    >
                                        Delete Project
                                    </DeleteButton>
                                    <div
                                        className="project-button"
                                        style={{ marginRight: '10px' }}
                                    >
                                        <Dropdown>{export_options}</Dropdown>
                                    </div>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        className="project-button"
                                        type="primary"
                                        href={`/project/${params['id']}/add_songs/`}
                                    >
                                        Add Songs
                                    </Button>
                                </div>
                                <div>
                                    <h2
                                        style={{
                                            marginRight: '20px',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {this.state.data.name}:
                                    </h2>
                                    <h3 style={{ display: 'inline-block' }}>
                                        {songs.length} total tracks
                                    </h3>
                                </div>
                                <table
                                    style={{
                                        width: '20%',
                                        display: 'inline-block',
                                        borderSpacing: '15px 2px',
                                        borderCollapse: 'separate',
                                    }}
                                >
                                    <tr>
                                        <td>
                                            <h5>To Be Tagged:</h5>
                                        </td>
                                        <td>{to_be_tagged} tracks</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>In Progress:</h5>
                                        </td>
                                        <td>{in_progress} tracks</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h5>Complete:</h5>
                                        </td>
                                        <td>{complete} tracks</td>
                                    </tr>
                                </table>
                                <div
                                    style={{
                                        float: 'right',
                                    }}
                                >
                                    <table
                                        style={{
                                            borderSpacing: '10px',
                                            borderCollapse: 'separate',
                                        }}
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <p>ML Confidence:</p>
                                            </td>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <p>Include Empty:</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    value={
                                                        this.state
                                                            .filter_threshold
                                                    }
                                                    style={{}}
                                                    class="slider"
                                                    id="myRange"
                                                    onInput={(e) =>
                                                        this.setState({
                                                            filter_threshold:
                                                                e.target.value,
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    onClick={() =>
                                                        this.setState({
                                                            include_empty:
                                                                !this.state
                                                                    .include_empty,
                                                        })
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {this.state.filter_threshold}%
                                                and lower
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="simple-card">
                                <h3>Project Notes:</h3>
                                <p>{this.state.data.project_notes}</p>
                            </div>
                            {songs}
                        </div>
                    </Loading>
                </Container>
            </div>
        );
    }
}
