export default function Dropdown(props) {
    return (
        <div class="dropdown">
            <a
                class="btn btn-primary dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                Export CSV
            </a>

            <ul
                class="dropdown-menu"
                style={{ background: '#141415', textAlign: 'center' }}
            >
                {props.children}
            </ul>
        </div>
    );
}
