import React, { Component } from 'react';

export default class SongListItem extends Component {
    render() {
        let data = this.props.data;

        let style = {};
        if (this.props.onClick) {
            style = { cursor: 'pointer' };
        }

        return (
            <div className={`simple-card`}>
                {this.props.children}
                <div style={style} onClick={this.props.onClick}>
                    <div className="song-title">{data['name']}</div>
                </div>
            </div>
        );
    }
}
