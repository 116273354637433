import React, { Component } from 'react';

import { Button } from 'library';

class Sentence extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let item = this.props.item;

        return (
            <div className="simple-card">
                <div style={{ float: 'right' }}>
                    <span class="badge text-bg-light">{item['structure']}</span>
                </div>
                <Button
                    type="primary"
                    onClick={() =>
                        this.props.update({
                            description: item['sentence'],
                            stripped_description: item['stripped_sentence'],
                        })
                    }
                    style={{ float: 'left', marginRight: '5px' }}
                >
                    Select
                </Button>
                <div>{item['sentence']}</div>
            </div>
        );
    }
}

export default class SentenceOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.data['sentences']) {
            return null;
        }

        let sentences = [];

        for (let item of this.props.data['sentences']) {
            sentences.push(<Sentence update={this.props.update} item={item} />);
        }
        return <div className="simple-card-container">{sentences}</div>;
    }
}
