import React, { Component } from 'react';
import { TagButton } from 'components';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: 'none',
        };
    }

    show_hide = () => {
        console.log('hide');
        if (this.state.show === 'none') {
            this.setState({ show: 'block' });
        } else {
            this.setState({ show: 'none' });
        }
    };

    render() {
        let parent = this.props.parent;
        let parent_jsx = '';
        if (parent !== null) {
            if (parent['selectable']) {
                let parent_active = this.props.song_tags.includes(parent['id'])
                    ? 'selected'
                    : '';
                parent_jsx = (
                    <div id="dropdown-click-zone">
                        <div
                            className={`tag-dropdown-header parent-selectable ${parent_active}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.toggle_tag(parent['id']);
                            }}
                        >
                            {parent.name}
                        </div>
                    </div>
                );
            } else {
                parent_jsx = (
                    <div className="tag-dropdown-header">{parent.name}</div>
                );
            }
        }

        let children_jsx = [];
        for (let tag of this.props.tags) {
            children_jsx.push(
                <TagButton
                    data={tag}
                    active={
                        this.props.song_tags.includes(tag['id']) ? 'active' : ''
                    }
                    toggle_tag={this.props.toggle_tag}
                />,
            );
        }

        return (
            <div class="tag-dropdown-container" onClick={this.show_hide}>
                {parent_jsx}
                <div style={{ display: this.state.show }}>{children_jsx}</div>
            </div>
        );
    }
}

export default class TagDropdowns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdowns: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setupTags();
        }
    }

    componentDidMount() {
        this.setupTags();
    }

    setupTags = () => {
        let parent_tags = {};
        let parents_children = {};
        let child_tags = [];
        let dropdowns = [];

        for (let tag of this.props.tags) {
            if (tag.parent === null) {
                parent_tags[tag.id] = tag;
            } else if (tag.parent in parents_children) {
                parents_children[tag.parent].push(tag);
            } else {
                parents_children[tag.parent] = [tag];
            }
        }

        // If only parent tags, i.e. no children, render list of buttons
        if (Object.keys(parents_children).length === 0) {
            for (let tag of this.props.tags) {
                dropdowns.push(
                    <TagButton
                        data={tag}
                        active={
                            this.props.song_tags.includes(tag['id'])
                                ? 'active'
                                : ''
                        }
                        toggle_tag={this.props.toggle_tag}
                    />,
                );
            }
            // Else render full list of dropdowns
        } else {
            for (let parent_id of Object.keys(parents_children)) {
                dropdowns.push(
                    <Dropdown
                        parent={parent_tags[parent_id]}
                        tags={
                            parents_children[parent_id]
                                ? parents_children[parent_id]
                                : []
                        }
                        song_tags={this.props.song_tags}
                        toggle_tag={this.props.toggle_tag}
                    />,
                );
            }
        }
        this.setState({ dropdowns: dropdowns });
    };

    render() {
        return <div>{this.state.dropdowns}</div>;
    }
}
