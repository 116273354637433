import React, { Component } from 'react';
import { Button, Image, AudioPlayer } from 'library';
import {
    ajax_wrapper,
    get_global_state,
    sort_objects,
    toast_handler,
} from 'functions';

import { DynamicSearch } from 'components';

export class TagDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:
                this.props.data.name !== 'Genres + Subgenres'
                    ? this.props.data.name
                    : 'Genres',
        };
    }

    render() {
        let tag_type = this.props.data;
        let tag_type_id = this.props.data.id;
        let name = this.state.name;
        let tags = this.props.tags;

        let tag_icons = [];
        let button_style = {};
        if (this.props.tiny) {
            button_style = {
                fontSize: '12px',
                padding: '2px 4px',
            };
        }

        for (let tag of tags) {
            let tag_button_style = { ...button_style };
            let tag_red_value = 97;
            if (tag.parent === null) {
                tag_red_value = 207;
            }
            tag_button_style['backgroundColor'] =
                `rgba(${tag_red_value}, 83, 167, .4)`;

            if (tag.ml_generated) {
                tag_button_style['backgroundColor'] =
                    `rgba(50, 99, 170, ${tag.ml_confidence})`;
            }

            tag_icons.push(
                <Button
                    style={tag_button_style}
                    className="song-tag"
                    disabled={this.props.editable ? false : true}
                    onClick={() => this.props.toggle_tag(tag.id)}
                >
                    {tag.name}
                </Button>,
            );
        }
        return (
            <div className="song-tag-display">
                <Button
                    style={button_style}
                    className="tag-type"
                    disabled={this.props.editable ? false : true}
                    onClick={() =>
                        this.props.show_search(
                            tag_type_id,
                            tag_type.name,
                            this.props.track_id,
                            tags,
                        )
                    }
                >
                    {name}
                </Button>
                {tag_icons}
            </div>
        );
    }
}

export class MiniPlayer extends Component {
    render() {
        return (
            <Image
                src="/static/images/mini-player-temp-image-3.png"
                className="mini-player-button"
            />
        );
    }
}

export default class SongCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: this.props.tags,
        };
    }

    toggle_tag = (id) => {
        let song_id = this.props.id;

        let data = {
            add: [],
            remove: [],
        };

        let found_tag = null;
        for (let item of this.state.tags) {
            if (item['id'] == id) {
                found_tag = this.state.tags.indexOf(item);
            }
        }
        if (found_tag == null) {
            data['add'].push(id);
            var new_state_tags = { ...this.state.tags };
            new_state_tags.push(this.state.tag_lookup[id]);
            this.setState({ tags: new_state_tags });
        } else {
            data['remove'].push(id);
            this.state.tags.splice(found_tag, 1);
        }

        sort_objects(this.state.tags, ['name']);

        this.setState(
            {
                tags: this.state.tags,
            },
            () =>
                ajax_wrapper(
                    'POST',
                    `/api/toggle_song_tags/${song_id}/`,
                    data,
                    (value) => toast_handler(value, null),
                ),
        );
    };

    render() {
        let data = this.props.data;
        let tags = this.state.tags;

        let tag_fields = [];

        for (let item of data.tag_types) {
            let tag_names = [];

            for (let tag of tags) {
                if (tag.tag_type === item.id) {
                    tag_names.push(tag);
                }
            }

            tag_fields.push(
                <TagDisplay
                    tiny={this.props.tiny}
                    editable={this.props.editable}
                    data={item}
                    track_id={data['id']}
                    tags={tag_names}
                    toggle_tag={this.props.toggle_tag}
                    show_search={this.props.show_search}
                />,
            );
        }

        let edit_button = null;
        if (this.props.editable) {
            edit_button = (
                <Button type="primary" href={`/song/${data['id']}/`}>
                    Edit
                </Button>
            );
        }

        return (
            <div
                className={`simple-card song-card ${
                    this.props.tiny ? 'tiny' : ''
                }`}
            >
                <div>
                    <div className="song-title">{data['name']}</div>
                    {edit_button}
                    {this.props.tiny ? null : (
                        <AudioPlayer
                            className="mini-player"
                            src={this.props.data['dropbox_url']}
                            style={{ float: 'right' }}
                        />
                    )}
                </div>
                {tag_fields}
                {this.props.children}
            </div>
        );
    }
}
