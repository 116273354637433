import React, { Component } from 'react';
import $ from 'jquery';

import { Modal, TextInput } from 'library';
import { TagButton, TagDropdowns } from 'components';
import { sort_objects } from 'functions';

export default class DynamicSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            search: '',
            tag_type: '',
            tag_type_name: '',
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.show && !prevProps.show) {

            this.setState({ 
                tag_type: this.props.tag_type ? this.props.tag_type : '',
                tag_type_name: this.props.tag_type ? this.props.tag_type_name : 'tags...' });

            setTimeout(
                function () {
                    $('.dynamic-search-input input').focus();
                }.bind(this),
                100,
            );
        } else if (!this.props.show && prevProps.show) {
            this.setState({ 
                            search: '',
                            tag_type: '',
                            tag_type_name: 'tags...',

                        });
        }
    }

    render() {
        let tag_lookup = this.props.tag_lookup;

        let song_tags = [];
        let song_tag_pool = [];

        let matched_tags_jsx = [];
        let dropdown_tags = [];
        let tag_dropdowns_jsx = '';

        if (this.props.show) { 

            // If called from project view, use tags from props, otherwise tags from data prop
            let prop_tags = this.props.project_view ? this.props.tags : this.props.data['tags'];

            // Push currently tagged items to tag pool (if matching current tag type)
            for (let item of prop_tags) {
                song_tags.push(item['id']);

                let tag_type_match = item['tag_type'] === this.state.tag_type ||
                                            this.state.tag_type === '';

                if (tag_type_match) {
                song_tag_pool.push(<TagButton 
                                        data={item} 
                                        active={'active'} 
                                        toggle_tag={this.props.toggle_tag}/>)
                }

            }

            // Populate list of currently available tags for tagtype, for search list and dropdowns
            let matched_tags = [];
            
            for (let key in tag_lookup) {
                let tag = tag_lookup[key];
                let tag_type_match = tag['tag_type'] === this.state.tag_type ||
                                        this.state.tag_type === '';

                if (
                    tag['name']
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()) &&
                    tag['selectable'] &&
                    tag_type_match &&
                    this.state.search

                ) {
                    matched_tags.push(tag);
                }

                if (tag_type_match) {
                    dropdown_tags.push(tag)

                }
            }

            // If search is showing, create dropdowns

            if (this.state.tag_type !== null && this.state.tag_type !== '') {
                tag_dropdowns_jsx = <TagDropdowns 
                                tags={ dropdown_tags } 
                                song_tags={ song_tags }
                                toggle_tag= { this.props.toggle_tag }
                                />;
            }

            // Populate search list, sorted

            sort_objects(matched_tags, ['name']);

            for (let tag of matched_tags) {
                let parent_text = tag_lookup[tag['tag_type']]['name'];
                if (tag['parent']) {
                    parent_text += ` > ${tag_lookup[tag['parent']]['name']}`;
                }

                matched_tags_jsx.push(
                    <TagButton
                        data={tag}
                        active={song_tags.includes(tag['id']) ? 'active' : ''}
                        toggle_tag={this.props.toggle_tag}
                        parent_text={parent_text}
                    />,
                );
            }
        }

        return (
            <Modal
                show={this.state.show || this.props.show}
                on_hide={this.props.on_hide}
            >
                <div className="simple-card-container">
                    <div
                        className="simple-card dynamic-search-input"
                        style={{ padding: '0px' }}
                    >
                        <TextInput
                            name="search"
                            placeholder={"Search " + this.state.tag_type_name}
                            autocomplete={'off'}
                            default={this.state.search}
                            handle_change={(event) =>
                                this.setState({
                                    search: event.target.value,
                                })
                            }
                            style={{
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                fontSize: '2rem',
                            }}
                        />
                    </div>
                </div>
                <div className="simple-card-container">
                    <div className='dynamic-tag-pool'>{this.state.tag_type_name}</div>
                    {song_tag_pool}
                </div>
                <div
                    className="simple-card-container row"
                    style={{ marginLeft: '0px', marginRight: '0px' }}
                >
                    {matched_tags_jsx}
                </div>

                <div className="simple-card-container">
                    {tag_dropdowns_jsx}
                </div>


            </Modal>
        );
    }
}
